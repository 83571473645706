<template>
  <div>
    <v-card flat elevation="0">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>List Item</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-text-field v-if="!isMobile" dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

        <v-btn icon @click="getData(true)" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="px-2" v-if="isMobile">
        <v-text-field class="mb-2" dense clearable autofocus outlined placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 3000px" hide-details single-line></v-text-field>
      </div>

      <v-data-table
        :options.sync="datatable_options"
        :loading="datatable.loading"
        :loading-text="datatable.loadingText"
        :headers="datatable.columns"
        :items="datatable.data"
        :search="search"
        :hide-default-header="isMobile"
        :server-items-length="datatable.totalRows"
        dense
        class="mb-3 my-0">
        <template v-slot:[`item.no_`]="{ item }" v-if="!isMobile">
          <span>{{ item.no_ }}</span>
        </template>
        <template v-slot:[`item`]="{ item }" v-if="isMobile">
          <v-card elevation="4" class="ma-1" outlined>
            <v-card-title class="text-h5 mb-1 pb-1">{{ item.no_ }}</v-card-title>
            <v-card-subtitle class="my-0 py-0 mb-3">
              {{ item.description }} <br>
              {{ item.base_unit_of_measure }}
            </v-card-subtitle>
          </v-card>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no results.</v-alert>s
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      loading: null,
      search: "",
      datatable_options: {},
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "No.", value: "no_" },
          { text: "Description", value: "description" },
          { text: "Search Description", value: "search_description" },
          { text: "Base UoM", value: "base_unit_of_measure" },
        ],
        data: [],
      },
    };
  },

  watch: {
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  
  methods:{
    getData(refresh) {

      if(refresh) this.AxiosStorageRemove("POST", "item/data");

      const { sortBy, sortDesc, page, itemsPerPage } = this.datatable_options;
      
      this.datatable.loading = true;
      var formData = new FormData();
      formData.append('search', this.search);
      formData.append('sort_by', sortBy);
      formData.append('sort_desc', sortDesc);
      formData.append('page', page);
      formData.append('limit', itemsPerPage);

      this.$axios
        .post("item/data", formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
  },

  mounted(){
    this.getData()
  }
};
</script>